import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CarReportTable from "../../Components/Tables/SmallTables/DailyCarReports/CarReportTable";
import ShiftReportTable from "../../Components/Tables/SmallTables/ShiftReports/ShiftReportTable";
import Charts from "../Charts/Charts";
import { Chart, registerables } from "chart.js";
import CargoLatestIssue from "../../Components/SingleComponents/CargoCar/CargoLatestIssue";
import DailyCarLatestIssue from "../../Components/DailyCar/DailyCarLatestIssue/DailyCarLatestIssue";
import StatusCard from "../../Charts/StatusChart/StatusCard";
import PerformanceCard from "../../Charts/PerformanceChart/PerformanceCard";
import EmployeePerformanceCard from "../../Charts/EmployeePerformance/EmployeePerformanceCard";

Chart.register(...registerables);

export const Dashboard = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <>
      <main className="flex justify-center items-center flex-col w-full">
        <section className="mb-10 hidden md:flex-col lg:flex-row md:flex justify-around items-center w-full h-full flex-col ">
          <PerformanceCard />
          <StatusCard />
        </section>

        <section className="flex md:flex-col lg:flex-row items-center justify-around w-full">
          {/* <div className="hidden md:flex">
            <EmployeePerformanceCard />
          </div> */}
          <div className="flex items-center justify-between flex-col md:flex-row my-4">
            <DailyCarLatestIssue />
            <CargoLatestIssue />
          </div>
        </section>

        <section className="mb-5 flex items-center justify-center flex-col">
          <ShiftReportTable />
          <CarReportTable />
        </section>
      </main>
    </>
  );
};

export default Dashboard;
